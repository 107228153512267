import React from 'react'
import { Grid, Box, Typography, Chip } from '@mui/material'

const EventItem = ({ value, suffix, label }) => {
  const CustomLabel = <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
    <Typography fontSize={13}>{label}</Typography>
    <Typography fontSize={13} fontWeight="bold">{value}{suffix}</Typography>
  </Box>
  return <Chip label={CustomLabel} size="small" />
}

const AssociatedInfoCell = ({ events }) => {
  return <Grid container sx={{ display: 'flex', flexDirection: 'row', gap: .5 }}>
    {_.map(events, event => <EventItem {...event} />)}
  </Grid>
}

export default AssociatedInfoCell
