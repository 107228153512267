import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'

const withContainer = Component => props => {
  const name = _.join(_.compact(['suspended', _.get(props, 'form_name')]), '_')

  const [motive] = hooks.useGlobalFieldValues(`${name}_dialog_form`, ['motive'])
  const setFormValues = hooks.useFormChange()
  const [suspended, setFormState] = hooks.useFormState(_.get(props, 'suspendedStateName') || 'suspended')

  const driver_status = _.get(suspended, 'name') || _.get(props, 'initialValues.name')

  const initialValues = !_.isEmpty(suspended) ? {
    motive: _.get(suspended, 'motive'),
    suspendedUntil: _.get(suspended, 'suspendedUntil'),
    suspendedSince: _.get(suspended, 'suspendedSince')
  } : _.get(props, 'initialValues')

  const onSuspendedClose = useCallback(Callbacks.onSuspendedCloseHandler(setFormValues, driver_status), [setFormValues, driver_status])
  const onSuspendedConfirm = useCallback(Callbacks.onSuspendedConfirmHandler(setFormState, setFormValues), [setFormState, setFormValues])

  const count_motive = useMemo(() => _.size(motive), [motive])

  const mergedProps = {
    count_motive,
    onSuspendedClose,
    onSuspendedConfirm,
    initialValues,
    name
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
